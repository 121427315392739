// components/ProjectCard.js
import React from "react";
import ReactGA from "react-ga";

const ProjectCard = ({ title, description, git, technologies, image }) => {
  const trackProjectView = () => {
    ReactGA.event({
      category: "Project",
      action: `View Project - ${title}`,
    });
  };

  return (
    <div className="max-w-sm bg-gray-900 border border-neutral-100 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      {title === 'Snap Shot' && (
        <a href="#" onClick={trackProjectView}>
          <img className="w-full rounded-t-lg h-auto object-cover" src={image.vpn} alt="" />
        </a>
      )}
      {title === 'Co People' && (
        <a href="#" onClick={trackProjectView}>
          <img className="w-full rounded-t-lg h-auto object-cover" src={image.copeople} alt="" />
        </a>
      )}
      <div className="p-4">
        <a href="#" onClick={trackProjectView}>
          <h5 className="text-2xl font-bold tracking-tight text-white bg-clip-text text-transparent bg-gradient-to-r from-yellow-200 to-pink-500">{title}</h5>
        </a>
        <p className="font-normal text-sm text-gray-300 dark:text-gray-400">{description}</p>
      </div>
      <div className="m-2 flex justify-between">
        <div className="flex flex-wrap gap-2 pl-2">
          {technologies.map((tag, index) => (
            <p key={`${index}-${tag}`} className="text-[14px] text-blue-500">
              #{tag}
            </p>
          ))}
        </div>
        <a href={git} className="text-red-300 border border-gray-200 rounded-lg shadow p-1 hover:text-green-500 duration-300" onClick={trackProjectView}>Project</a>
      </div>
    </div>
  );
};

export default ProjectCard;
