// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Route, Routes } from 'react-router-dom';
import About from './components/About';
import Contact from './components/Contact';
import Experience from './components/Experience';
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Projects from './components/Projects';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';
import trackIP from './trackIP'; // Import the trackIP function

ReactGA.initialize('G-RJ9Y05541K'); // Replace with your tracking ID

const root = ReactDOM.createRoot(document.getElementById('root'));

trackIP(); // Track IP when the app loads

root.render(
    <React.StrictMode>
      <HashRouter>
        <>
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/experience' element={<Experience />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
          </Routes>
        </>
      </HashRouter>
    </React.StrictMode>
);

reportWebVitals();
