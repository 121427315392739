// components/Projects.js
import React from "react";
import ProjectCard from "./ProjectCard";
import Footer from './Footer';
import ReactGA from "react-ga";
import vpn from '../assets/vpn.png';
import copeople from '../assets/copeople.png';
const Projects = () => {
  ReactGA.pageview(window.location.pathname + window.location.search); // Track initial pageview

  return (
    <div className="flex flex-col min-h-screen bg-black">
      <div className="flex-grow flex flex-wrap gap-7 justify-center items-center m-12 p-12">
        {project.map((item, index) => (
          <ProjectCard
            key={index}
            image={item.image}
            title={item.title}
            description={item.description}
            git={item.git}
            technologies={item.technologies}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export const project = [
  {
    title: 'Flight Reservation System',
    description: 'Development of a mobile application and website allowing users to search, book and manage their flights.',
    image: { vpn },
    git: 'https://github.com/mouad-ou/',
    technologies: ["Flutter", 'ReactJS', 'Spring Boot']
  },
  {
    title: 'Smart city bike application',
    description: 'SnapShot is a stunning portfolio that I exclusively designed using React JS and tailwind CSS.This Project serves as a representation of a photographer’s work, highlighting their portfolio and services.',
    image: { copeople },
    git: "https://github.com/mouad-ou/Smart-Bicycle-s-city",
    technologies: ["Flutter", 'Google Maps API', "Firebase", "Angular"]
  },
  {
    title: 'Disney+ Data Analytics Projects',
    description: 'In-depth analysis of Disney+ content.',
    image: { copeople },
    git: "https://www.kaggle.com/code/obviousg/notebook914112afc6",
    technologies: ["Python(pandas, Matplotlib, Seaborn)"]
  }
];

export default Projects;
