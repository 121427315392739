// src/trackIP.js
import axios from 'axios';

const trackIP = async () => {
  try {
    const response = await axios.get('https://us-central1-portfolio-593f6.cloudfunctions.net/trackIP');
    console.log('IP tracked successfully:', response.data);
  } catch (error) {
    console.error('Error tracking IP:', error);
  }
};

export default trackIP;
