import React, { useEffect, useRef, useState } from 'react';
import { name } from '../constants';
import ReactGA from 'react-ga'; // Import react-ga
import Footer from './Footer';

const Home = () => {
  const ref = useRef(0);
  const [text, setText] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      if (ref.current < name.length) {
        ref.current++;
        setText(() => text + name[ref.current - 1]);
      }
    }, 500);
    return () => clearInterval(interval);
  }, [text]);

  const trackHeroClick = () => {
    // Track hero click event in Google Analytics
    ReactGA.event({
      category: 'Hero Section',
      action: 'Clicked Hero',
    });
  };

  return (
    <div className='area relative z-0 bg-black w-screen h-screen'>
      <ul className="circles">
        {/* Animated circles */}
        {Array.from({ length: 10 }, (_, index) => (
          <li key={index}></li>
        ))}
      </ul>
      <div
        className='hero relative h-[calc(100vh)] flex justify-center items-center text-white'
        id='hero'
        onClick={trackHeroClick} // Track click on hero section
      >
        <div className='pt-4 h-36 backdrop-blur-sm rounded-3xl max-w-screen-xl mx-auto px-4'>
          <h1 className='text-6xl sm:text-7xl font-extrabold mt-2'>
            Hi, I'm&nbsp;
            <span className='font-extrabold' style={{ color: '#1F2937' }}>{text}</span>
          </h1>
          <p className='mt-3 text-xl'>I love to learn and build scalable and optimized backend systems.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
