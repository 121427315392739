import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EMAIL_JS_PUBLIC_KEY, EMAIL_JS_SERVICE_ID, EMAIL_JS_TEMPLATE_ID_FOR_CLIENT } from "../constants";
import Footer from "./Footer";
import ReactGA from 'react-ga'; // Import react-ga

const Contact = () => {
	const formRef = useRef();
	const [form, setForm] = useState({
		name: "",
		email: "",
		message: "",
	});
	const [loading, setLoading] = useState(false);

	const handleChange = (e) => {
		const { target } = e;
		const { name, value } = target;
		setForm({
			...form,
			[name]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);

		const username = form.name.trim();
		const user_email = form.email.trim();
		const user_message = form.message.trim();

		if (username === '' || user_email === '' || user_message === '') {
			setLoading(false);
			toast.error("Please fill all the fields.", {
				position: 'bottom-right',
			});
			return;
		}

		// Track button click event in Google Analytics
		ReactGA.event({
			category: 'Contact Form',
			action: 'Send Message Clicked',
		});

		emailjs.send(EMAIL_JS_SERVICE_ID, EMAIL_JS_TEMPLATE_ID_FOR_CLIENT, {
			from_name: username,
			to_email: user_email,
			message: user_message,
		}, EMAIL_JS_PUBLIC_KEY)
			.then(
				() => {
					setLoading(false);
					toast.success("Message sent successfully!", {
						position: 'bottom-right',
					});
					setForm({
						name: "",
						email: "",
						message: "",
					});
				},
				(error) => {
					setLoading(false);
					console.error(error);
					toast.error("Uh, oh! Something went wrong. Please try again later.", {
						position: 'bottom-right',
					});
				}
			);
	};

	return (
		<>
			<section className="py-16 bg-gray-900 text-white mb-0" id="contact">
				<div className="container mx-auto px-4">
					<div className="flex flex-wrap justify-center items-center -mx-4">
						<div className="w-full md:w-1/2 px-4 mb-4 md:mb-0"> 
							{/* Image Section */}
							<div className="blurred-border rounded-md shadow-md">
								<img
									src="https://wspace.ma/assets/img/contact.webp"
									alt="mouaad"
									className="rounded-md"
								/>
							</div>
						</div>

						<div className="w-full md:w-1/2 px-4">
							{/* Form Section */}
							<div className="blurred-border p-6 rounded-md shadow-md">
								<h2 className="text-3xl font-bold mb-4">
									Let's Discuss Your Project
								</h2>
								<p className="mb-6">
									Always available for freelancing if the right project comes
									along. Feel free to contact me.
								</p>

								<form
									ref={formRef}
									onSubmit={handleSubmit}
									className="flex flex-col gap-4"
								>
									{/* Input Fields */}
									<div className="flex flex-wrap -mx-2 mb-4">
										<div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
											<label
												htmlFor="name"
												className="block text-sm font-medium mb-1"
											>
												First Name
											</label>
											<input
												type="text"
												id="name"
												name="name"
												value={form.name}
												onChange={handleChange}
												className="w-full px-3 py-2 border border-gray-400 rounded-md focus:outline-none focus:border-blue-500 text-white bg-gray-800"
												placeholder="Enter your first name"
												required
											/>
										</div>
										<div className="w-full md:w-1/2 px-2">
											<label
												htmlFor="email"
												className="block text-sm font-medium mb-1"
											>
												Your Email
											</label>
											<input
												type="email"
												id="email"
												name="email"
												value={form.email}
												onChange={handleChange}
												className="w-full px-3 py-2 border border-gray-400 rounded-md focus:outline-none focus:border-blue-500 text-white bg-gray-800"
												placeholder="Enter your email address"
												required
											/>
										</div>
									</div>
									{/* Message Area */}
									<div className="mb-4">
										<label
											htmlFor="message"
											className="block text-sm font-medium mb-1"
										>
											Your Message
										</label>
										<textarea
											id="message"
											name="message"
											rows="5"
											value={form.message}
											onChange={handleChange}
											className="w-full px-3 py-2 border border-gray-400 rounded-md focus:outline-none focus:border-blue-500 text-white bg-gray-800"
											placeholder="Write your message here"
											required
										></textarea>
									</div>
									{/* Submit Button */}
									<button
										type="submit"
										className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
										onClick={() => ReactGA.event({
											category: 'Contact Form',
											action: 'Send Message Clicked',
										})}
									>
										{loading ? "Sending..." : "Send Message"}
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
				<ToastContainer />
			</section>
			<Footer className="mb-0" />
		</>
	);
};

export default Contact;
