import React from 'react';
import { AiFillLinkedin } from 'react-icons/ai';
import { FaGithub, FaInstagram } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import ReactGA from 'react-ga'; // Import react-ga

const Footer = () => {
  const year = new Date().getFullYear();

  const trackSocialMediaClick = (platform) => {
    // Track social media click event in Google Analytics
    ReactGA.event({
      category: 'Social Media',
      action: `Clicked ${platform} Icon`,
    });
  };

  return (
    <div className='bg-slate-900 p-4' style={{ cursor: 'default' }}>
      <div className='flex justify-between flex-wrap gap-4'>
        <p className='text-white text-center w-full sm:w-auto font-light'>© {year} Mouaad Ouammou. All rights reserved.</p>
        <div className='text-white flex justify-around sm:w-[250px] w-full'>
          <a
            href="mailto:mouadouammou1@gmail.com"
            onClick={() => trackSocialMediaClick('Email')}
            className='transition ease-in-out duration-300 rounded-md hover:scale-110 cursor-pointer hover:-translate-y-1'
            style={{ cursor: 'pointer' }}
          >
            <FiMail className='text-xl' />
          </a>
          <a
            href="https://www.linkedin.com/in/mouaad-ouammou/"
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => trackSocialMediaClick('LinkedIn')}
            className='transition ease-in-out duration-300 rounded-md hover:scale-110 cursor-pointer hover:-translate-y-1'
            style={{ cursor: 'pointer' }}
          >
            <AiFillLinkedin className='text-xl' />
          </a>
          <a
            href="https://github.com/mouad-ou/"
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => trackSocialMediaClick('GitHub')}
            className='transition ease-in-out duration-300 rounded-md hover:scale-110 cursor-pointer hover:-translate-y-1'
            style={{ cursor: 'pointer' }}
          >
            <FaGithub className='text-xl' />
          </a>
          <a
            href="https://www.instagram.com/mouad_obg"
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => trackSocialMediaClick('Instagram')}
            className='transition ease-in-out duration-300 rounded-md hover:scale-110 cursor-pointer hover:-translate-y-1'
            style={{ cursor: 'pointer' }}
          >
            <FaInstagram className='text-xl' />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
